import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Layout from "../../../components/layout"
import Seo from "../../../components/seo"

const DryBulkAndTankerOceanFreight = () => {
    return (
        <Layout>
            <Seo title="Drybulk & Tanker Ocean Freight" />
            <div className="container py-3 py-lg-4">
                <div className="row g-0 mb-3">
                    <div className="col border-bottom border-1 border-dark">
                        <h1>Drybulk & Tanker Ocean Freight</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="card mb-3 border-0">
                        <div className="row gx-3 ">
                            <div className="col-lg-6">
                                <div className="card-body gray-bg d-flex flex-column">
                                    <h2>Dry bulk</h2>
                                    <p className="card-text">
                                        A blended chartering strategy that utilises a multi-prong approach of Time Charters, arbitrage
                                        of cargo and ships, FFA’s to hedge exposure while maintaining flexibility to gain exposure for
                                        market moves.
                                    </p>
                                    <p>
                                        Focusing on non-cape segment owing to the fragmented ownership/control allows the company to
                                        operate in a liquid market. The Asset ownership model comes with residual risk which arises
                                        many years down that cannot be easily measured or controlled, while the Asset light model
                                        approach risk is ascertainable at the time of the trade. Furthermore, no technical risk on the
                                        trading of ships due to changes in regulation,.e.g. IMO 2020. Delta capitalises on niche
                                        regional flows ignored by other market participants.
                                    </p>
                                    <p>
                                        The versatile trading methodology gives Delta the ability to switch between various vessel
                                        sizes – Handysize thru to Kamsarmax depending on the margin and market conditions.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <StaticImage
                                    transformOptions={{ cropFocus: "attention" }}
                                    alt="Sales team"
                                    src="../../../images/other/bulker1.jpg"
                                    aspectRatio={16 / 9}
                                    width={696}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="card mb-3 border-0">
                        <div className="row gx-3 ">
                            <div className="col-lg-6">
                                <div className="card-body gray-bg d-flex flex-column">
                                    <h2>Wet bulk</h2>
                                    <p className="card-text">
                                        Delta also handles Tanker freight and has a robust chartering platform that allows it to
                                        service the requirements of its clients especially on clean products. It is actively engaged in chartering of tanker vessels for its fuel supply and distribution needs.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <StaticImage
                                    transformOptions={{ cropFocus: "attention" }}
                                    alt="Sales team"
                                    src="../../../images/other/tanker1.jpg"
                                    aspectRatio={16 / 9}
                                    width={696}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default DryBulkAndTankerOceanFreight
